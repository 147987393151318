<!-- 修改密码 -->
<template>
    <div>
        <van-nav-bar title="修改密码"  left-arrow  @click-left="onClickLeft"/>
        <div class="switchBox">
          <span class="stepbtn" style="border-right:1px solid #f2f3f4" :class="isActive==1?'active':''" @click="switchBind(1)">1,验证原密码</span>
          <img src="../assets/imgs/0.png" alt="" style="width:34px">
          <span class="stepbtn" :class="isActive==2?'active':''" @click="switchBind(2)">2,设置新密码</span>
        </div>
        <van-form @submit="onSubmit" style="padding:20px;background-color: #f2f3f4;">
            <p v-if="isActive==1" class="grayword" style="font-size: 14px;padding:20px 0">为保障您的账户安全,修改密码前请填写原密码</p>
            <van-field
                v-if="isActive==1"
                v-model="passwordForm.oldPassword"
                type="password"
                left-icon="lock"
                placeholder="请输入密码"
                :rules="[{ required: true}]"
                class="custom-field"
            />
            <van-field
                v-if="isActive==2"
                v-model="passwordForm.oldPassword"
                type="password"
                left-icon="lock"
                placeholder="请输入密码"
                :rules="[{ required: true}]"
                class="custom-field"
            />
            <van-field
                v-if="isActive==2"
                v-model="passwordForm.newPassword"
                type="password"
                left-icon="lock"
                placeholder="请再次输入新的登录密码"
                :rules="[{ required: true}]"
                style="border-top:none"
                class="custom-field"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="button" v-if="isActive==1" @click="toNext">下一步</van-button>
                <van-button round block type="info" native-type="submit" v-else>提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { passwordUpdate } from '@/api/base'
import { Toast } from 'vant';
import { Notify } from 'vant';
export default {
  name: 'revisepassword',
  data(){
    return {
        isActive:1,
        passwordForm:{
          oldPassword:'',
          newPassword:''
        }
    }
  },
  mounted(){

  },
  methods:{
    toNext() {
      this.isActive=2;
    },
    onSubmit(values) {
      console.log('submit', values);
      passwordUpdate(this.passwordForm).then((res)=>{
          if(res.code==0){
            Notify({ type: 'success', message: '更新成功!' });
            this.$router.push('/logon')
          }else{
            Notify({ type: 'warning', message: res.msg });
          }
      })
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    switchBind(e){
      this.isActive=e;
    }
  }
}
</script>
<style lang="scss" scoped>
  .content_section{
     height:100%;
   }
   .switchBox{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f3f4;
    .stepbtn{
      flex: 1;
       text-align: center;
    }
   }
   .stepbtn.active{
    color:cornflowerblue;
   }
   .custom-field{
     border:1px solid #f2f3f4;
   }
  /deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
    color: #ccc !important; 
    /* font-size: 24px !important; */
  }

</style>
